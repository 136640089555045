import {graphql} from "gatsby";
import {Layout} from "../components/Layout";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import React from "react";
import {Typography} from "../components/Typography";
import * as styles from './404.module.scss';
import {useLocation} from "@reach/router";

export const query = graphql`
    query Page404 {
        services: allStrapiServiceV3(filter: {locale:{eq:"en"}}) {
            nodes {
                isMain
                name
                slug
                shortDescription
            }
        }

        companyData: strapiCompanyData(locale: {eq: "en"}) {
            name
            street
            postalCode
            city
            country
            phones {
                phoneNumber
            }
            emails {
                emailAddress
            }
        }

        jobs: allStrapiJobOffer(filter: {locale:{eq:"en"}}) {
            totalCount
        }
    }
`

const PL_LANG = /^\/pl\//;

const TRANSLATIONS = {
    pl: {
        notFound: {
            title: "Nie ma takiej strony",
            description: "Nie ma takiej strony"
        },
    },
    en: {
        notFound: {
            title: "Page not found",
            description: "There is no such page"
        },
    }
}
export default function NotFoundPage({data}) {
    const location = useLocation();
    const lang = PL_LANG.test(location.pathname) ? 'pl' : 'en';

    return <Layout services={data.services.nodes.filter(x => x.isMain)}
                   oldServices={data.oldServices}
                   jobsCount={data.jobs.totalCount}
                   linkableSubServices={data.services.nodes.filter(x => !x.isMain)}
                   title={TRANSLATIONS[lang].notFound.title}
                   companyData={data.companyData}
                   lang={lang}>
        <PageMainColumnWrapper>
            <Typography className={styles.root}>
                <h1>{TRANSLATIONS[lang].notFound.description}</h1>
            </Typography>
        </PageMainColumnWrapper>
    </Layout>;
}
